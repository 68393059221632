/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useMemo } from "react";
import { When } from "react-if";
import { FlexContainer, theme, Button, Text } from "@nordcloud/gnui";
import { usePeopleQuery } from "~/generated/graphql";
import { ContactSelector } from "~/components/Inputs/ContactSelector";
import { useDisclosure, useToggle } from "~/hooks";
import { isNotNil, isNotEmpty } from "~/tools";

type DetailsFieldProps = {
  label: string;
  value: string;
  onEdit?: (id: string) => void;
  onDelete?: (id?: string) => void;
};

export function ContactDetailsField({
  label,
  value = "No Data Provided",
  onEdit,
  onDelete,
}: DetailsFieldProps) {
  const [isHovered, toggleIsHovered] = useToggle();
  const { isOpen, open, close } = useDisclosure();
  const { data, loading } = usePeopleQuery();
  const contacts = useMemo(() => (data?.people ?? []).filter(isNotNil), [data]);

  const hasValue = isNotEmpty(value);
  const text = hasValue ? value : "No Data Provided";
  const textColor = hasValue
    ? theme.color.text.text01
    : theme.color.text.text03;
  const isInteractable = isNotNil(onEdit) || isNotNil(onDelete);

  const showDeleteButton = isNotNil(onDelete) && isHovered;
  const showEditButton = isNotNil(onEdit) && isHovered;

  return (
    <>
      <FlexContainer
        gap={theme.spacing.spacing02}
        alignItems="center"
        onMouseEnter={toggleIsHovered}
        onMouseLeave={toggleIsHovered}
      >
        <div>
          <Text size="sm" tag="div" color={theme.color.text.text03}>
            {label}
          </Text>
          <Text
            color={textColor}
            tag="div"
            mr={theme.spacing.spacing02}
            css={{
              borderBottom: isInteractable ? "1px dotted black" : "none",
            }}
          >
            {text}
          </Text>
        </div>
        <FlexContainer gap={theme.spacing.spacing02}>
          <When condition={showEditButton}>
            <Button
              severity="low"
              p={theme.spacing.spacing01}
              icon="edit"
              onClick={open}
            />
            <ContactSelector
              isOpen={isOpen}
              options={contacts}
              peopleLoading={loading}
              onClose={close}
              onSubmit={(id) => {
                onEdit?.(id);
                close();
              }}
            />
          </When>
          <When condition={showDeleteButton}>
            <Button
              severity="low"
              p={theme.spacing.spacing01}
              icon="trash"
              onClick={() => onDelete?.()}
            />
          </When>
        </FlexContainer>
      </FlexContainer>
    </>
  );
}
