/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When } from "react-if";
import {
  Box,
  Button,
  FlexContainer,
  ModalConfirm,
  Text,
  theme,
} from "@nordcloud/gnui";
import { CloudAccountsQueryVariables } from "~/generated/graphql";
import { ACTION, PopoverControls } from "~/components";
import { useDisclosure, useQueryState } from "~/hooks";
import { useCustomers } from "~/services/customers";
import { Maybe, isNotEmpty, noop } from "~/tools";
import { useCloudAccountBatchDelete } from "../hooks";
import { CloudAccountsQueryState, ProviderTab } from "../types";
import { ActiveFilters } from "./ActiveFilters";
import { CloudAccountsSearchBox } from "./CloudAccountsSearchBox";
import { useBillingDataStatus } from "./useBillingDataStatus";

type Props = {
  searchInput?: Maybe<string>;
  selectedAccountsNids: string[];
  fetchCloudAccountsVariables: CloudAccountsQueryVariables;
  onSetSearchInput: (input: string) => void;
  openFiltersSidebar: () => void;
  openBillingDataSidebar: () => void;
  openAddNewAccountSidebar: () => void;
  onUnselectAllAccounts: () => void;
  openCredentialsSidebar: () => void;
};

export function CloudAccountFilterBox({
  searchInput,
  selectedAccountsNids,
  fetchCloudAccountsVariables,
  onSetSearchInput,
  openFiltersSidebar,
  openBillingDataSidebar,
  openAddNewAccountSidebar,
  onUnselectAllAccounts,
  openCredentialsSidebar,
}: Props) {
  const {
    state: { provider },
  } = useQueryState<CloudAccountsQueryState>();

  const { isAdminLite } = useCustomers();

  const { isBillingDataInactive } = useBillingDataStatus(provider);

  const {
    isOpen: isConfirmModalOpen,
    open: openConfirmModal,
    close: closeConfirmModal,
  } = useDisclosure();

  const [batchDeleteCloudAccounts] = useCloudAccountBatchDelete({
    responseParameters: fetchCloudAccountsVariables,
  });

  const handleBatchDelete = async () => {
    await batchDeleteCloudAccounts(selectedAccountsNids);
    closeConfirmModal();
    onUnselectAllAccounts();
  };

  const showBillingDataButton = [
    ProviderTab.AWS,
    ProviderTab.AZURE,
    ProviderTab.GCP,
  ].includes(provider);

  const canUpdateCredentials = ![
    ProviderTab.VMWARE,
    ProviderTab.KUBERNETES,
    ProviderTab.OPENSHIFT,
  ].includes(provider);

  const popoverButtons = [
    {
      label: "Delete Accounts",
      action: ACTION.DELETE,
      onClick: isNotEmpty(selectedAccountsNids) ? openConfirmModal : noop,
    },
  ];

  if (canUpdateCredentials) {
    const updateButton = {
      label: "Update Credentials",
      action: ACTION.CUSTOM_TRIGGER,
      customIcon: "settings",
      onClick: isNotEmpty(selectedAccountsNids) ? openCredentialsSidebar : noop,
    };
    popoverButtons.unshift(updateButton);
  }

  return (
    <Box boxStyle="lightGrey" spacing="spacing04">
      <FlexContainer
        justifyContent="space-between"
        alignContent="center"
        gap={theme.spacing.spacing05}
      >
        <div
          css={{
            flexGrow: 1,
          }}
        >
          <CloudAccountsSearchBox
            searchInput={searchInput ?? ""}
            onSetSearchInput={onSetSearchInput}
          />
        </div>
        <Button severity="low" icon="filter" onClick={openFiltersSidebar}>
          Filters
        </Button>
        <div
          css={{
            height: "2.25rem",
            borderRight: `1px solid ${theme.color.border.border01}`,
          }}
        />
        <FlexContainer gap={theme.spacing.spacing06}>
          <When condition={showBillingDataButton}>
            <Button
              severity="low"
              icon="costSettings"
              color={isBillingDataInactive ? "danger" : undefined}
              onClick={openBillingDataSidebar}
            >
              Billing Data
            </Button>
          </When>
          <When condition={!isAdminLite}>
            <Button icon="plus" onClick={openAddNewAccountSidebar}>
              Add new Account
            </Button>
          </When>
          <PopoverControls
            trigger={<Button severity="low" icon="menu" />}
            items={popoverButtons}
          />
        </FlexContainer>
        <ModalConfirm
          confirm={handleBatchDelete}
          actionLabel="Delete"
          contentLabel="Delete Accounts"
          isOpen={isConfirmModalOpen}
          onClose={closeConfirmModal}
        >
          <Text>Do you want to delete selected accounts?</Text>
        </ModalConfirm>
      </FlexContainer>
      <ActiveFilters />
    </Box>
  );
}
